import React, { useContext } from 'react';
import { Element } from 'react-scroll';
import accenture from '../../images/Accenture.png';
import HPE from '../../images/HPE.png';
import microsoft from '../../images/Microsoft.jpg';
import tmobile from '../../images/tmobile-trans.png';
import { Heading } from '../modules/Typography';
import { Container } from '../modules/Grid';
import { WidthContext } from '../../contexts/WidthContextProvider';

const PartnersTile = props => {
    const { isMobile, size, isLarge } = useContext(WidthContext);
    let number = 3;
    if (isLarge) {
        number = 2;
    } else if (isMobile) {
        number = 4;
    }

    return (
        <div className='PartnersTile'>
            <Element name='partners' className='scroll-element' />
            <div className='PartnersTile__text__container flex column align-center flex-start'>
                <div className='PartnersTile__text__group'>
                    <Heading className={`PartnersTile__text ${!isMobile && !size === 'md' ? 'desktop' : ''}`} number={number}>Innovation Partners</Heading>
                </div>
                <div className='PartnersTile__divider' />
                <div className='PartnersTile__logo__row'>
                    <a className='PartnersTile__logo__link__1' target='_blank' rel='noreferrer' href='https://www.accenture.com/us-en/services/interactive/disney-studiolab'>
                        <img className='PartnersTile__logo accenture' src={accenture} />
                    </a>
                    <a className='PartnersTile__logo__link__1' target='_blank' rel='noreferrer' href='https://www.hpe.com/us/en/insights/articles/disney-s-studiolab-accelerates-filmmaking-innovation-2002.html'>
                        <img className='PartnersTile__logo hpe' src={HPE} />
                    </a>
                    <a className='PartnersTile__logo__link__1 end' target='_blank' rel='noreferrer' href='https://news.microsoft.com/2019/09/13/microsoft-and-the-walt-disney-studios-to-develop-scene-to-screen-content-workflows/'>
                        <img className='PartnersTile__logo microsoft' src={microsoft} />
                    </a>
                    <div className='PartnersTile__logo__row__bottom'>
                        <a className='PartnersTile__logo__link__2' target='_blank' rel='noreferrer' href='https://www.t-mobile.com/news/network/t-mobile-and-disney-studiolab-team-up'>
                            <img className='PartnersTile__logo tmobile' src={tmobile} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartnersTile;
